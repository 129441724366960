$local-link-color: #13a4eb;

a,
a:active,
a:hover,
a:visited {
  color: $local-text !important;
  text-decoration: none;
  padding-bottom: rem-calc(4);
  -webkit-transition: border-bottom-color 500ms ease;
  -moz-transition: border-bottom-color 500ms ease;
  -ms-transition: border-bottom-color 500ms ease;
  -o-transition: border-bottom-color 500ms ease;
  transition: border-bottom-color 500ms ease;
  border-bottom-color: rgba(255, 255, 255, 0);
}

a:hover {
  border-bottom-width: 1px;
  border-bottom-color: $local-text;
  border-bottom-style: solid;
}

body {
  @if $local-bkg-image != none {
    background: url($local-bkg-image) no-repeat center center fixed #333333 !important;
    background-size: cover;
    -webkit-background-size: cover;
    -moz-background-size: cover;
  }
}

.top-bar {
  .for-sale {
    margin-left: auto;
    margin-right: auto;
    text-align: center;
  }
  .top-bar-title {
    padding-right: rem-calc(10);
  }
  font-family: $local-headline-font;
  font-size: rem-calc(22);
  color: $local-topbar-text !important;
  line-height: 175%;
  a,
  a:active,
  a:hover,
  a:visited {
    color: $local-topbar-text !important;
  }
  a:hover {
    border-bottom-color: $local-topbar-text !important;
  }
  a.instagram:hover,
  a.twitter:hover {
    border-bottom-color: rgba(255, 255, 255, 0) !important;
  }
  .tagline,
  .twitter,
  .instagram {
    display: none;
    @include breakpoint(700px) {
      display: inline-block;
    }
  }
}

.distribution-bar,
.social-bar {
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  margin-top: rem-calc(20);
  margin-bottom: rem-calc(20);
  max-width: 100%;
  width: rem-calc($local-description-width);
}

a.home:hover,
a.instagram:hover,
a.facebook:hover,
a.twitter:hover,
a.itunes:hover {
  border-bottom-style: none;
}

.instagram,
.facebook {
  margin-left: rem-calc(10);
}

.home-icon,
.twitter-logo,
.facebook-logo,
.instagram-logo {
  width: rem-calc(36);
  max-width: rem-calc(36);
}

.itunes-logo {
  width: rem-calc(200);
  max-width: rem-calc(200);
}

.hero {
  margin-top: 4%;
}

.logo-gfx,
.logo {
  width: -webkit-min-content;
  width: -moz-min-content;
  width: min-content;
  display: block;
  margin-left: auto;
  margin-right: auto;
  max-width: rem-calc(400);
  float: none !important;
}
.logo {
  margin-top: $local-logo-margin-top;
  max-width: 100%;
  width: rem-calc($local-logo-width);
}
h1,
h3,
h6,
p {
  font-family: $local-text-font !important;
  font-weight: 700 !important;
  text-shadow: 1px 1px 3px rgba(100,100,100,0.85);
}
h2,
h4 {
  font-family: $local-headline-font !important;
}
h2 {
  font-size: rem-calc(32) !important;
}
h3 {
  font-size: rem-calc(22) !important;
}
h4 {
  font-size: rem-calc(20) !important;
}
h5 {
  font-family: $local-text-font !important;
  margin-left: auto;
  margin-right: auto;
  font-size: rem-calc(16) !important;
  color: $local-text;
  max-width: rem-calc(300);
  opacity: 0;
  -webkit-transition: opacity 0.5s ease-in;
  -moz-transition: opacity 0.5s ease-in;
  -ms-transition: opacity 0.5s ease-in;
  -o-transition: opacity 0.5s ease-in;
  transition: opacity 0.5s ease-in;
}
h6 {
  font-weight: bold !important;
  font-size: rem-calc(14) !important;
  line-height: 175% !important;
}
.description {
  margin-left: auto;
  margin-right: auto;
  font-size: rem-calc(20px);
  margin-top: rem-calc(10px);
  max-width: 100%;
  width: rem-calc($local-description-width);
}

.fade {
  opacity: 1;
}

.clear {
  float: none;
  clear: both;
}

.info-text {
  padding-top: $local-logo-margin-bottom;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: $local-text-font;
  line-height: 175%;
}

.ad {
  padding-top: rem-calc(10);
  padding-bottom: rem-calc(10);
  margin-left: auto;
  margin-right: auto;
  text-align: center;
}

.content-page {
  .ad {
    padding-top: rem-calc(20);
    padding-bottom: rem-calc(20);
  }
  .ad.plain {
    padding-top: 0;
    padding-bottom: 0;
    .adsbygoogle {
      margin-top: rem-calc(15);
      margin-bottom: rem-calc(15);
    }
  }
  .arrow-down {
    margin-top: rem-calc(10);
    display: block;
    margin-left: auto;
    margin-right: auto;
  }
  h1 {
    margin-bottom: 0;
  }
  .content-row {
    background: #ffffff;
    color: #000000 !important;
    margin-top: 0;
    margin-bottom: rem-calc(30);
    margin-left: 0 !important;
    margin-right: 0 !important;
    .columns {
      padding-left: 0 !important;
      padding-right: 0 !important;
    }
    a {
      color: #000000 !important;
    }
    a:hover {
      border-bottom-color: #000000 !important;
    }
    .caption {
      margin-top: rem-calc(5);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
      font-size: rem-calc(10);
      line-height: 175%;
      .image-credit {
      }
    }
    .copy {
      margin-top: rem-calc(30);
      margin-bottom: rem-calc(30);
      padding-left: rem-calc(15);
      padding-right: rem-calc(15);
      p {
        line-height: 175%;
        a,
        a:active,
        a:hover,
        a:visited {
          color: $local-link-color !important;
        }
      }
      .content-by {
        text-align: right;
        font-size: rem-calc(12);
        img {
          margin-left: rem-calc(2);
          max-width: rem-calc(86);
          position: relative;
          top: rem-calc(-4);
        }
      }
      .related {
        margin-top: rem-calc(30);
        margin-bottom: rem-calc(5);
        font-size: rem-calc(10);
        font-weight: bold !important;
        line-height: 200% !important;
      }
      .related.sites {
        margin-top: 0;
        font-size: rem-calc(12);
        a,
        a:active,
        a:hover,
        a:visited {
          color: $local-text !important;
        }
      }
    }
    .copy {
      @include breakpoint(medium up) {
        max-width: rem-calc(800);
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
  .description {
    margin-top: 0;
    margin-bottom: $local-logo-margin-bottom;
  }
  .info-text {
    padding-top: 0;
    padding-bottom: rem-calc(30);
  }
  h2,
  h3 {
    margin-bottom: 1rem;
  }
  .content-image {
    margin-top: 0 !important;
    width: 100%;
    max-width: 100%;
  }
  .two-columns {
    .logo {
      margin-top: 0;
      width: auto;
      max-width: 90%;
    }
    .description {
      padding-bottom: rem-calc(30);
    }
    .copy {
      margin-bottom: rem-calc(30);
      margin-left: auto;
      margin-right: auto;
    }
  }
}

.ad-background {
	background-color: $local-topbar;
  overflow: hidden;
	.ad-top {
		padding-top: 0;
		padding-bottom: 0;
		margin-right: auto;
		margin-left: auto;
		text-align: center;
	}
}

.sitegrid {
  .column {
    height: 350px;
    min-height: 350px;
    margin-top: auto;
    margin-bottom: auto;
  }
  .logo {
    max-width: 90% !important;
    width: 90% !important;
    max-height: rem-calc(200);
  }
  .description {
    max-width: 90% !important;
    width: 90% !important;
  }
}
