// Colors
$local-topbar: #ffcc00;
$local-topbar-text: #333333;
$local-bkg: #fcfcfc;
$local-bkg-image: '../img/s01/e01/coffee_bkg.jpg';
$local-text: #ffffff;

// Font
$local-text-font: 'Open Sans', sans-serif;
$local-headline-font: 'Oswald', sans-serif;

// Dimensions
$local-topbar-height: 70px;
$local-logo-width: 400px;
$local-logo-margin-top: 170px;
$local-logo-margin-bottom: 1px;
$local-description-width: 600px;
